import React from 'react';
import { useBalance } from '../context/BalanceContext';

export const Balance = () => {
	const { state: { balance } } = useBalance();

	return (
		<div className="bg-black rounded-lg bg-opacity-50 text-white px-4 p-1">
			<span>${(balance / 100).toFixed(2)}</span>
		</div>
	);
};
